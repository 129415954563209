<template>
  <div class="d-flex flex-column mt-10">
    <div class="d-flex justify-center">
      <v-img
        v-if="imageUrl"
        :src="imageUrl"
        :aspect-ratio="1 / 1"
        :max-height="$vuetify.breakpoint.xs ? 200 : 278"
        :max-width="$vuetify.breakpoint.xs ? 200 : 278"
        class="rounded-circle"
      />
      <v-avatar
        v-else
        class="d-flex justify-center"
        :height="$vuetify.breakpoint.xs ? 200 : 278"
        :width="$vuetify.breakpoint.xs ? 200 : 278"
      >
        <v-icon :size="$vuetify.breakpoint.xs ? 200 : 278">fa-user</v-icon>
      </v-avatar>
    </div>
    <div class="mt-4 d-flex justify-center">
      <a
        class="text-decoration-underline font-weight-bold"
        style="color: inherit"
        @click="onPickFile"
      >
        Subir foto
      </a>
    </div>
    <input
      ref="fileInput"
      type="file"
      style="display: none"
      accept="image/*"
      @change="onFilePicked"
    />
  </div>
</template>

<script>
import { fileToBase64 } from "../../Utils/Utils";
export default {
  props: {
    imageUrl: {
      type: String || null,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    onPickFile() {
      this.$refs.fileInput.click();
    },
    async onFilePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Favor de subir un archivo valido");
      }
      let stringFile = await fileToBase64(files[0], false);

      this.$emit("uploadPictureFile", {
        fileBase64: stringFile,
        filename: filename,
      });
    },
  },
};
</script>

<style scoped></style>
