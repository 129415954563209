var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","ma-0":"","pa-0":""}},[_c('div',{staticClass:"purple-gradient fill-height"},[_c('Navbar')],1),_c('div',{style:([
      _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
        ? {
            'padding-top': '30px',
            'padding-bottom': '30px',
          }
        : {
            'padding-top': '30px',
            'padding-bottom': '70px',
          } ])},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('Photo-upload')],1)],1)],1),_c('v-container',{staticClass:"body-container"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"10"}},[_c('h1',[_vm._v("Mi perfil")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"10"}},[_c('h2',[_vm._v("Informacion de general")])]),_c('v-col',{attrs:{"cols":"1"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"10"}},[_c('v-container',{staticClass:"d-flex flex-column flex-md-row pa-0"},[_c('v-container',{staticClass:"mx-md-8 mx-0 pa-0"},[_c('label',{attrs:{"for":"userName"}},[_c('h4',[_c('div',{staticClass:"d-flex"},[_vm._v(" Nombre legal "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("El nombre legal se mostrará en los contratos de los servicios contratados.")])])],1)])]),_c('v-text-field',{attrs:{"outlined":"","dense":"","rounded":"","rules":_vm.nameRules,"color":"primary"}})],1),_c('v-container',{staticClass:"mx-md-8 mx-0 pa-0"},[_c('label',{attrs:{"for":"userEmail"}},[_c('h4',[_vm._v("Apellidos")])]),_c('v-text-field',{attrs:{"outlined":"","dense":"","rounded":"","rules":_vm.nameRules,"color":"primary"}})],1)],1),_c('v-container',{staticClass:"d-flex flex-column flex-md-row pa-0"},[_c('v-container',{staticClass:"mx-md-8 mx-0 pa-0"},[_c('label',{attrs:{"for":"userEmail"}},[_c('h4',[_vm._v("Correo electrónico")])]),_c('div',{staticClass:"ml-3 mt-3"},[_vm._v("correo@gmail.com")])]),_c('v-container',{staticClass:"mx-md-8 mx-0 pa-0"})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-16"},[_c('v-col',{attrs:{"cols":"1","sm":"6"}}),_c('v-col',{attrs:{"cols":"10","sm":"5"}},[_c('v-container',{staticClass:"d-flex justify-center justify-md-end mx-md-8 mx-0 pa-0"},[_c('Button',{attrs:{"text":"Guardar cambios","type":"submit","aria-label":"Guardar cambios"}})],1)],1)],1)],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }