<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar />
    </div>
    <div
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? {
              'padding-top': '30px',
              'padding-bottom': '30px',
            }
          : {
              'padding-top': '30px',
              'padding-bottom': '70px',
            },
      ]"
    >
      <v-row>
        <v-col cols="12" align="center">
          <Photo-upload />
        </v-col>
      </v-row>
    </div>
    <v-container class="body-container">
      <v-form ref="form">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <h1>Mi perfil</h1>
          </v-col>
        </v-row>

        <!-- Provider Info. -->
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <h2>Informacion de general</h2>
          </v-col>
          <v-col cols="1"></v-col>

          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <v-container class="d-flex flex-column flex-md-row pa-0">
                <v-container class="mx-md-8 mx-0 pa-0">
                  <label for="userName"
                    ><h4>
                      <div class="d-flex">
                        Nombre legal
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="ml-1"
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span
                            >El nombre legal se mostrará en los contratos de los
                            servicios contratados.</span
                          >
                        </v-tooltip>
                      </div>
                    </h4></label
                  >
                  <v-text-field
                    outlined
                    dense
                    rounded
                    :rules="nameRules"
                    color="primary"
                  ></v-text-field>
                </v-container>
                <v-container class="mx-md-8 mx-0 pa-0">
                  <label for="userEmail"><h4>Apellidos</h4></label>
                  <v-text-field
                    outlined
                    dense
                    rounded
                    :rules="nameRules"
                    color="primary"
                  ></v-text-field>
                </v-container>
              </v-container>
              <v-container class="d-flex flex-column flex-md-row pa-0">
                <v-container class="mx-md-8 mx-0 pa-0">
                  <label for="userEmail"><h4>Correo electrónico</h4></label>
                  <div class="ml-3 mt-3">correo@gmail.com</div>
                </v-container>
                <v-container class="mx-md-8 mx-0 pa-0"> </v-container>
              </v-container>
            </v-col>
          </v-row>
        </v-row>

        <v-row class="mb-16">
          <v-col cols="1" sm="6"></v-col>
          <v-col cols="10" sm="5">
            <v-container
              class="d-flex justify-center justify-md-end mx-md-8 mx-0 pa-0"
            >
              <Button
                text="Guardar cambios"
                type="submit"
                aria-label="Guardar cambios"
              />
            </v-container>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <Footer />
  </v-container>
</template>

<script>
import Navbar from "@/components/Admin/Shared/Navbar.vue";
import PhotoUpload from "@/components/Shared/PhotoUpload.vue";
import Button from "@/components/Shared/Button.vue";
import Footer from "@/components/Shared/Footer.vue";
import { nameRules } from "@/Utils/rules";

export default {
  name: "ProfileUser",
  components: {
    Navbar,
    Button,
    PhotoUpload,
    Footer,
  },
  data: () => ({
    nameRules: nameRules,
  }),
};
</script>

<style scoped>
.purple-gradient {
  background-color: rgba(98, 37, 130, 255);
}
</style>
